import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from './axios_instance';
import { format } from 'date-fns';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { CgProfile ,CgTrash  } from "react-icons/cg";

const FinancialHistory = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const employee_Id = searchParams.get('employee_Id');
    const employee_Name = searchParams.get('employee_Name');
    console.log("employee_Id", employee_Name);
    const [historyData, setHistoryData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const downloadLinkRef = useRef(null);

    const isTokenValid = (token) => {
        return token !== null && token !== undefined;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('Token');

                if (!isTokenValid(token)) {
                    navigate('/dashboard');
                    return;
                }
                setIsLoading(true);

                axiosInstance
                    .get("history/employees/" + employee_Id)
                    .then((response) => {
                        console.log("response", response.data);
                        setHistoryData(response.data.financeHistory);
                    })
                    .catch((error) => {
                        console.error("Error fetching history:", error);
                        setIsLoading(false);
                    });
            } catch (error) {
                console.error("Error occurred", error)
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const formatDate = (dateString) => {
        if (!dateString) {
            return null;
        }

        const dateObject = new Date(dateString);
        return dateObject.toLocaleDateString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric' });
    };

    const downloadCSV = () => {
        const csvRows = [];

        // Adding headers for Payment Data
        const paymentHeaders = [
            "Date",
            "CTC Annual",
            "BASIC PAY (Annual)",
            "HRA (Annual)",
            "GROSS TOTAL (Annual)"
        ];
        csvRows.push(paymentHeaders.join(','));

        // Add Payment Data
        historyData.forEach(historyItem => {
            const row = [
                historyItem.updatedDate ? formatDate(historyItem.updatedDate) : "---",
                historyItem.ctcAnnual || "---",
                historyItem.basicPayAnnual || "---",
                historyItem.hraAnnual || "---",
                historyItem.grossTotalAnnual || "---"
            ];
            csvRows.push(row.join(','));
        });

        // Adding empty row for spacing between Payment and Hike Data
        csvRows.push([]);

        // Adding headers for Hike Data
        const hikeHeaders = [
            "HIKE DATE",
            "HIKE AMOUNT",
            "HIKE PERCENTAGE"
        ];
        csvRows.push(hikeHeaders.join(','));

        // Add Hike Data
        let hikeDataAdded = false;
        historyData.forEach(historyItem => {
            (historyItem.hikeDataList || []).forEach(hike => {
                const row = [
                    formatDate(hike.hikeDate),
                    hike.hikeAmount || "---",
                    hike.hikePercent || "---"
                ];
                csvRows.push(row.join(','));
                hikeDataAdded = true;
            });
        });
        if (!hikeDataAdded) {
            csvRows.push(["No hike details found", "", ""]);
        }

        // Adding empty row for spacing between Hike and Bonus Data
        csvRows.push([]);

        // Adding headers for Bonus Data
        const bonusHeaders = [
            "BONUS DATE",
            "BONUS TYPE",
            "BONUS AMOUNT"
        ];
        csvRows.push(bonusHeaders.join(','));

        // Add Bonus Data
        let bonusDataAdded = false;
        historyData.forEach(historyItem => {
            (historyItem.bonusDataList || []).forEach(bonus => {
                const row = [
                    formatDate(bonus.bonusDate),
                    bonus.bonusType || "---",
                    bonus.bonusAmount || "---"
                ];
                csvRows.push(row.join(','));
                bonusDataAdded = true;
            });
        });
        if (!bonusDataAdded) {
            csvRows.push(["No bonus details found", "", ""]);
        }

        // Combine rows into a single CSV string
        const csvString = csvRows.join('\n');

        // Create a Blob from the CSV data
        const blob = new Blob([csvString], { type: 'text/csv' });

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Set the href and download attributes of the hidden link
        const filename = `${employee_Name}_financial_history.csv`;
        downloadLinkRef.current.href = url;
        downloadLinkRef.current.download = filename;

        // Simulate a click on the hidden link
        downloadLinkRef.current.click();

        // Clean up by revoking the temporary URL
        window.URL.revokeObjectURL(url);
    };

 
    
    

    const printPDF = () => {
        const doc = new jsPDF();

        // Add main title "Finance History"
        doc.setFontSize(16);
        const mainTitle = "Finance History";
        const mainTitleWidth = doc.getStringUnitWidth(mainTitle) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const mainTitleXPos = (doc.internal.pageSize.width - mainTitleWidth) / 2;
        doc.text(mainTitle, mainTitleXPos, 20);

        // Table column settings for Payment Data
        const paymentCols = ["DATE", "CTC Annual", "BASIC PAY Annual", "HRA Annual", "GROSS TOTAL Annual"];
        const paymentRows = [];

        // Add Payment Data
        historyData.forEach(historyItem => {
            const row = [
                historyItem.updatedDate ? formatDate(historyItem.updatedDate) : "---",
                historyItem.ctcAnnual || "---",
                historyItem.basicPayAnnual || "---",
                historyItem.hraAnnual || "---",
                historyItem.grossTotalAnnual || "---"
            ];
            paymentRows.push(row);
        });

        // Add Payment Data table
        doc.autoTable({
            head: [paymentCols],
            body: paymentRows,
            theme: 'grid',
            styles: { fontSize: 10, fontStyle: 'normal',textAlign :'left' },
            headerStyles: { fillColor: [100, 100, 100], textColor: [255, 255, 255], fontStyle: 'bold' },
            startY: 30,
            margin: { top: 30 },
        });

        // Add space between tables
        doc.text("", 20, doc.autoTable.previous.finalY + 10);

        // Table column settings for Hike Data
        const hikeCols = ["HIKE DATE", "HIKE AMOUNT", "HIKE PERCENTAGE"];
        const hikeRows = [];

        // Add Hike Data
        historyData.forEach(historyItem => {
            (historyItem.hikeDataList || []).forEach(hike => {
                const row = [
                    formatDate(hike.hikeDate),
                    hike.hikeAmount || "---",
                    hike.hikePercent || "---"
                ];
                hikeRows.push(row);
            });
        });

        // Check if there is no hike data
        if (hikeRows.length === 0) {
            hikeRows.push([{ content: "No hike details found", colSpan: 3, styles: { halign: 'center' } }]);
        }

        // Add Hike Data table below Payment Data table
        doc.autoTable({
            head: [hikeCols],
            body: hikeRows,
            theme: 'grid',
            styles: { fontSize: 10, fontStyle: 'normal',textAlign :'left' },
            headerStyles: { fillColor: [100, 100, 100], textColor: [255, 255, 255], fontStyle: 'bold' },
            startY: doc.autoTable.previous.finalY + 20,
            margin: { top: 20 },
        });

        // Add space between tables
        doc.text("", 20, doc.autoTable.previous.finalY + 10);

        // Table column settings for Bonus Data
        const bonusCols = ["BONUS DATE","BONUS TYPE", "BONUS AMOUNT"];
        const bonusRows = [];

        // Add Bonus Data
        historyData.forEach(historyItem => {
            (historyItem.bonusDataList || []).forEach(bonus => {
                const row = [
                    bonus.bonusType || "---",
                    formatDate(bonus.bonusDate),
                    bonus.bonusAmount || "---"
                ];
                bonusRows.push(row);
            });
        });

        // Check if there is no bonus data
        if (bonusRows.length === 0) {
            bonusRows.push([{ content: "No bonus details found", colSpan: 3, styles: { halign: 'center' } }]);
        }

        // Add Bonus Data table below Hike Data table
        doc.autoTable({
            head: [bonusCols],
            body: bonusRows,
            theme: 'grid',
            styles: { fontSize: 10, fontStyle: 'normal',textAlign: 'left' },
            headerStyles: { fillColor: [100, 100, 100], textColor: [255, 255, 255], fontStyle: 'bold' },
            startY: doc.autoTable.previous.finalY + 20,
            margin: { top: 20 },
        });

        // Save the PDF
        doc.save('financial_history.pdf');
    };



    const handleDeleteHistory = async (employeeId, indexType, index) => {
        setIsLoading(true); // Start loading
        console.log("index",index)
        const confirmDelete = window.confirm("Are you sure you want to delete this record?");
        if (!confirmDelete) return; // Exit if the user cancels

        try {
          // Make an HTTP request to the backend to delete the history entry
          const response = await axios.post(process.env.REACT_APP_IP + 'history/deleteHistorydetails', null, {
            params: {
              employeeID: employeeId, // Corrected to match your backend expectations
              historyType: indexType,
              rowIndex: index,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('Token')}`,
            },
          });
    
          // Check the response from the server
          if (response.status === 200) {
            // Update state or perform any additional actions if needed
            console.log('History entry deleted successfully');
            toast.success("History entry deleted successfully", { position: toast.POSITION.TOP_CENTER });
            // Optionally, refresh or re-fetch the data instead of reload
            window.location.reload()
          } else {
            console.error('Failed to delete history entry');
          }
        } catch (error) {
          console.error('Error deleting history entry:', error);
          toast.error("Error deleting history entry", { position: toast.POSITION.TOP_CENTER });
        } finally {
          setIsLoading(false); // Stop loading
        }
      };
    



    const hasRequiredFields = (data) => {
        const requiredFields = ['ctcAnnual', 'ctcMonthly', 'basicPayAnnual', 'basicPayMonthly', 'epfAnnual', 'epfMonthly', 'hraAnnual', 'hraMonthly', 'grossTotalAnnual', 'grossTotalMonthly', 'otherAllowanceAnnual', 'otherAllowanceMonthly', 'reimbursementExpenseAnnual', 'reimbursementExpenseMonthly', 'specialAllowanceAnnual', 'specialAllowanceMonthly', 'transportAllowanceAnnual', 'transportAllowanceMonthly', 'variablePayAnnual', 'variablePayMonthly'];
        return requiredFields.every(field => data.hasOwnProperty(field));
    };


    const renderEmployeeDetails = () => {


        const tableStyle = {
            width: '100%',
            borderCollapse: 'collapse',
            marginBottom: '20px',
        };

        const thTdStyle = {
            border: '1px solid #ddd',
            padding: '8px',
            textAlign: 'left',
        };

        const thStyle = {
            ...thTdStyle,
            backgroundColor: '#f2f2f2',
            fontWeight: 'bold',
        };

        const noDataStyle = {
            ...thTdStyle,
            textAlign: 'center',
            padding: '10px',
        };


        const columns = [
            { key: 'updatedDate', label: 'Date' },
            { key: 'ctcAnnual', label: 'CTC (Annual)' },
            { key: 'basicPayAnnual', label: 'BASIC PAY (Annual)' },
            { key: 'hraAnnual', label: 'HRA (Annual)' },
            { key: 'grossTotalAnnual', label: 'GROSS TOTAL (Annual)' },
        ];

        const formatDate = (dateString) => {
            if (!dateString) {
                return null;
            }

            const dateObject = new Date(dateString);
            const year = dateObject.getFullYear();
            const month = String(dateObject.getMonth() + 1).padStart(2, '0');
            const day = String(dateObject.getDate()).padStart(2, '0');
            return `${day}-${month}-${year}`; // Get the date in YYYY-MM-DD format
        };



        return (
            <div class="mt-4">
                {/* Payment History table */}
                <div style={{ width: '100%' }}>
                    <h5>Payment Details</h5>
                    <div style={{ overflowX: 'auto' }}>
                        <table style={tableStyle}>
                            <thead>
                                <tr>
                                    {columns.map((col) => (
                                        <th key={col.key} style={thStyle}>{col.label}</th>
                                        
                                    ))}
                                    <th style={thStyle}>Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ cursor: "pointer" }}>
                                {historyData === null || historyData.length === 0 ? (
                                    <tr>
                                        <td colSpan={columns.length +1}>---</td>
                                    </tr>
                                ) : (
                                    historyData.map((historyItem, index) => (
                                        <tr key={index}>
                                            {columns.map((col) => (
                                                <td key={col.key} style={thTdStyle}>
                                                    {col.key === 'updatedDate'
                                                        ? formatDate(historyItem[col.key])
                                                        : historyItem[col.key] != null
                                                            ? historyItem[col.key]
                                                            : '----'}
                                                </td>
                                            ))}
                                            <td style={thTdStyle}>
                                            <CgTrash onClick={() => handleDeleteHistory(employee_Id, 'financeHistory', index)} size={20} />
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Hike Data table */}
                <div style={{ width: '100%' }}>
                    <h5>Hike Details</h5>
                    <div style={{ overflowX: 'auto' }}>
                        <table style={tableStyle}>
                            <thead>
                                <tr>
                                    <th style={thStyle}>HIKE DATE</th>
                                    <th style={thStyle}>HIKE AMOUNT</th>
                                    <th style={thStyle}>HIKE PERCENTAGE</th>
                                    <th style={thStyle}>Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ cursor: "pointer" }}>
                                {historyData === null || historyData.length === 0 || !historyData.some(item => item.hikeDataList && item.hikeDataList.length > 0) ? (
                                    <tr>
                                        <td style={noDataStyle} colSpan={3}>No hike details found</td>
                                    </tr>
                                ) : (
                                    historyData.map((historyItem, index) => (
                                        <React.Fragment key={index}>
                                            {(historyItem.hikeDataList || []).map((hike, hikeIndex) => {
                                                // Check if this hike data has been displayed before
                                                const previousHikes = historyData.slice(0, index).flatMap(item => item.hikeDataList || []);
                                                const hikeAlreadyDisplayed = previousHikes.some(prevHike =>
                                                    prevHike.hikeDate === hike.hikeDate &&
                                                    prevHike.hikeAmount === hike.hikeAmount &&
                                                    prevHike.hikePercent === hike.hikePercent
                                                );

                                                // If hike data is already displayed, skip rendering this row
                                                if (hikeAlreadyDisplayed) {
                                                    return null;
                                                }

                                                // Otherwise, display the hike data
                                                return (
                                                    <tr key={`hike_${index}_${hikeIndex}`}>
                                                        <td style={thTdStyle}>{hike.hikeDate ? formatDate(hike.hikeDate) : "---"}</td>
                                                        <td style={thTdStyle}>{hike.hikeAmount || "---"}</td>
                                                        <td style={thTdStyle}>{hike.hikePercent || "---"}</td>
                                                        <td style={thTdStyle}>
                                                        <CgTrash onClick={() => handleDeleteHistory(employee_Id, "financeHistory", index)}size={20}/>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </React.Fragment>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Bonus Data table */}
                <div style={{ width: '100%' }}>
                    <h5>Bonus Details</h5>
                    <div style={{ overflowX: 'auto' }}>
                        <table style={tableStyle}>
                            <thead>
                                <tr>
                                    <th style={thStyle}>BONUS DATE</th>
                                    <th style={thStyle}>BONUS TYPE</th>
                                    <th style={thStyle}>BONUS AMOUNT</th>
                                    {(historyData !== null || historyData.length ===0)&&
                                     <th style={thStyle}>Action</th>
                                    }
                                </tr>
                            </thead>
                            <tbody style={{ cursor: "pointer" }}>
                                {historyData === null || historyData.length === 0 || !historyData.some(item => item.bonusDataList && item.bonusDataList.length > 0) ? (
                                    <tr>
                                        <td colSpan={3} style={noDataStyle}>No bonus details found</td>
                                    </tr>
                                ) : (
                                    historyData.map((historyItem, index) => (
                                        <React.Fragment key={index}>
                                            {(historyItem.bonusDataList || []).map((bonus, bonusIndex) => {
                                                // Check if this bonus type has been displayed before
                                                const previousBonuses = historyData.slice(0, index).flatMap(item => item.bonusDataList || []);
                                                const bonusAlreadyDisplayed = previousBonuses.some(prevBonus => prevBonus.bonusType === bonus.bonusType);

                                                // If bonus type is already displayed, skip rendering this row
                                                if (bonusAlreadyDisplayed || !bonus.bonusDate) {
                                                    return null;
                                                }

                                                // Otherwise, display the bonus data
                                                return (
                                                    <tr key={`bonus_${index}_${bonusIndex}`}>
                                                        <td style={thTdStyle}>{bonus.bonusDate ? formatDate(bonus.bonusDate) : "---"}</td>
                                                        <td style={thTdStyle}>{bonus.bonusType || "---"}</td>
                                                        <td style={thTdStyle}>{bonus.bonusAmount || "---"}</td>
                                                        <td style={thTdStyle}>
                                                        <CgTrash onClick={() => handleDeleteHistory(employee_Id, "financeHistory", index)}size={20}/>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </React.Fragment>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };

    const handleBack = () => {
        navigate("/finance");
    };


    return (
        <div style={{ minHeight: '100vh', backgroundColor: "#f2edf3" }}>
            <nav className="navbar navbar-expand-lg navbar-dark bg-white">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        <img src="exalogo.png" alt="Logo" className="logo" />
                    </a>
                    <h2>
                        <span style={{ color: "rgb(10, 56, 115)" }}>HR </span>
                        <span style={{ color: 'rgb(255, 0, 0)' }}>INFORMATION </span>
                        <span style={{ color: 'rgb(10, 56, 115)' }}>SYSTEM</span>
                    </h2>
                    <button className="btn btn-primary text-white" onClick={handleBack}>
                        Back To Dashboard
                    </button>
                </div>
            </nav>
            <div className="row mt-3">
                <div className="d-flex justify-content-end">
                    <button
                        className="btn btn-primary mt-2"
                        onClick={downloadCSV}
                    >
                        Download
                    </button>
                    <a
                        ref={downloadLinkRef}
                        style={{ display: 'none' }}
                        href="/"
                        download="financial_history.csv"
                    >
                        Download Link
                    </a>
                </div>
                <div className="col-md-12">
                    <div className="card" style={{ borderRadius: "5px" }}>
                        <div className="card-body" style={{ backgroundColor: "rgb(10, 56, 115)", padding: "10px" }}>
                            <h3 className="card-title text-center" style={{ color: "white", margin: "0" }}>Financial History</h3>
                        </div>
                        <div>
                            {renderEmployeeDetails()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FinancialHistory;